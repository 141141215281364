@tailwind base;
@tailwind components;
@tailwind utilities;

.root,
#root,
#docs-root {
    --primary: #5B6AB0;
    --secondary: #5B6AB0;
    --background-color: #EEF1F8;
    --rounded: 15px;
    --shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
}

* {
    font-family: 'Jost', sans-serif;
}
/* input[type="text"], textarea {

  background-color : #d1d1d1;

} */
.panel {
  display: none;
}
.panel.active{
  display: block;
}